import React, { useEffect } from 'react';
import './MondialRelayWidget.scss';
import { changeInputOrder } from '../../../../actions/cartAction';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../Commons/Spinner/Spinner';

const MondialRelayWidget = () => {

  const dispatch = useDispatch();
  const isMondialRelayWidgetLoading = useSelector(
    (state) => state.cart.isMondialRelayWidgetLoading
)
const openMondialRelayWidget = useSelector(
  (state) => state.cart.openMondialRelayWidget
)
const mondialRelayId = useSelector(
  (state) => state.cart.mondialRelayId
)
const mondialRelayName = useSelector(
  (state) => state.cart.mondialRelayName
)

const mondialRelayPostCode = useSelector(
  (state) => state.cart.mondialRelayPostCode
)

const mondialRelayCountryCode = useSelector(
  (state) => state.cart.mondialRelayCountryCode
)

const postCodeShipping = useSelector(
  (state) => state.cart.postCodeShipping
)
const countryShipping = useSelector(
  (state) => state.cart.countryShipping
)

  useEffect(() => {
    const loadScript = (src, id) => {
      console.log(" je suis dans le useeffect du widget")
      return new Promise((resolve, reject) => {
        // Vérifie si le script est déjà chargé
        const existingScript = document.getElementById(id);
        if (!existingScript) {
          const script = document.createElement('script');
          script.src = src;
          script.id = id;
          script.onload = resolve;
          script.onerror = reject;
          document.body.appendChild(script);
        } else {
          resolve(); // Si le script est déjà chargé, on résout immédiatement
        }
      });
    };

    const loadStylesheet = (href, id) => {
      return new Promise((resolve, reject) => {
        const existingStylesheet = document.getElementById(id);
        if (!existingStylesheet) {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = href;
          link.id = id;
          link.onload = resolve;
          link.onerror = reject;
          document.head.appendChild(link);
        } else {
          resolve(); // Si le stylesheet est déjà chargé, on résout immédiatement
        }
      });
    };

    const loadScriptsAndStyles = async () => {
      try {
        // Charger jQuery 2.2.4
        await loadScript('https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js', 'jquery-2.2.4');
        // Charger Leaflet
        await loadScript('https://unpkg.com/leaflet/dist/leaflet.js', 'leaflet-js');
        // Charger la feuille de style Leaflet
        await loadStylesheet('https://unpkg.com/leaflet/dist/leaflet.css', 'leaflet-css');
        // Charger le widget Mondial Relay après jQuery
        await loadScript('https://widget.mondialrelay.com/parcelshop-picker/jquery.plugin.mondialrelay.parcelshoppicker.min.js', 'mondialrelay-plugin');

        // Vérification continue jusqu'à ce que le plugin Mondial Relay soit chargé
        const checkMondialRelayPlugin = setInterval(() => {
          if (typeof window.jQuery.fn.MR_ParcelShopPicker !== 'undefined') {
            clearInterval(checkMondialRelayPlugin);

            // Initialiser le widget Mondial Relay
            window.jQuery("#Zone_Widget").MR_ParcelShopPicker({
              Target: "#ParcelShopCode",
              Brand: "CC239P6S",
              Country: countryShipping,
              Responsive: true,
              PostCode: postCodeShipping,
              EnableGeolocalisatedSearch: true,
              AutoSelect: mondialRelayId ? `${mondialRelayCountryCode}-${mondialRelayId}` : null,                
              OnParcelShopSelected: function (data) {
               dispatch(changeInputOrder(data.ID, "mondialRelayId"));
               dispatch(changeInputOrder(data.Nom, "mondialRelayName")) 
               dispatch(changeInputOrder(data.Adresse1 + " " + data.Adresse2, "mondialRelayStreet"))
              dispatch(changeInputOrder(data.CP, "mondialRelayPostCode")) 
              dispatch(changeInputOrder(data.Ville, "mondialRelayCity")) 
              dispatch(changeInputOrder(data.Pays, "mondialRelayCountryCode")) 

              },
            });

            dispatch(changeInputOrder(false, "isMondialRelayWidgetLoading"))
          }
        }, 100);  // Vérifie toutes les 100ms si le plugin est chargé
      } catch (error) {
        console.error("Erreur lors du chargement des scripts Mondial Relay : ", error);
      }
    };

    loadScriptsAndStyles();  // Charger les scripts et styles

    // Nettoyage des scripts et des styles au démontage du composant
    return () => {
      const removeElementById = (id) => {
        const element = document.getElementById(id);
        if (element && element.parentNode) {
          element.parentNode.removeChild(element);
        }
      };

      removeElementById('jquery-2.2.4');
      removeElementById('leaflet-js');
      removeElementById('leaflet-css');
      removeElementById('mondialrelay-plugin');
    };
  }, []);

  return (
    <div className='mondial-relay_wrapper'>
        {isMondialRelayWidgetLoading && ( 
              <Spinner/>
        )} 
      {/* <div 
        className='cross'
        onClick={() => {
          dispatch(changeInputOrder(!openMondialRelayWidget, "openMondialRelayWidget"))
        }}
      > 
        FERMER X
      </div> */}
      <div className='widget-and-selection-wrapper'>
        <div id="Zone_Widget">
        </div>
        {!isMondialRelayWidgetLoading && (
                <div className="selected-point-relay">
                  {mondialRelayId !== null && (
                    <p className='name' id="selectedRelayInfo">{mondialRelayName}</p>

                  )}
                  {mondialRelayId == null && (
                    <p id="selectedRelayInfo">Aucun point relais sélectionné.</p>
                  )}
                
                <button
                  onClick={() => {
                    dispatch(changeInputOrder(!openMondialRelayWidget, "openMondialRelayWidget"))
                  }}
                  id="validateRelay" disabled={mondialRelayId === null ? true : false}
                  >
                    Valider le point relais
                  </button>
              </div>
        )}
        </div>
    </div>
  );
};

export default MondialRelayWidget;
