import "./Livraison.scss"
import InputSimple from "../../UserAccount/InputSimple/inputSimple"
import { useSelector, useDispatch } from "react-redux"
import {
    toggleBillingAddress,
    changeInputOrder,
    putAddAddressOrder,
    patchShippingMethod,
} from "../../../actions/cartAction"
import { useEffect, useState } from "react"
import { fetchAddresses, fetchCountries } from "../../../actions/userAction"
import Spinner from "../../Commons/Spinner/Spinner"
import { useNavigate } from "react-router-dom"
import MondialRelayWidget from "./MondialRelayWidget/MondialRelayWidget"
import MondialRelaySelect from "./MondialRelaySelect/MondialRelaySelect"

const Livraison = () => {
    const dispatch = useDispatch()
    const payplugUrl = useSelector((state) => state.cart.payplugUrl)
    const navigate = useNavigate()
    const cartData = useSelector((state) => state.cart.cartData)
    const loadingCart = useSelector((state) => state.cart.loadingCart)
    const redirectPaiement = useSelector((state) => state.cart.redirectPaiement)
    const addresses = useSelector((state) => state.user.addresses)
    const defaultAddress = useSelector((state) => state.user.defaultAddress)
    const mondialRelayId = useSelector(
        (state) => state.cart.mondialRelayId
      )

      useEffect(() => {
        if (Object.keys(cartData).length < 1) {
            navigate("/mon-panier")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartData])


    useEffect(() => {
        dispatch(fetchCountries())
        dispatch(fetchAddresses())
        dispatch(changeInputOrder(false, "adressValidate"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])



    useEffect(() => {
        if (
            cartData.shippingAddress !== null &&
            cartData.shippingAddress !== undefined
        ) {
            // dispatch(changeInputOrder(true, "addressValidate"))

            if (cartData.shipments.length === 0) {
                // dispatch(getCartConnected(store.getState().user.customerId));

                dispatch(changeInputOrder(false, "chooseDelivery"))
                dispatch(changeInputOrder(false, "loadingValidateAddress"))
            } else {
                dispatch(
                    changeInputOrder(
                        cartData.shipments[0].method.id,
                        "shipmentSelected"
                    )
                )

                dispatch(changeInputOrder(true, "chooseDelivery"))
                dispatch(changeInputOrder(false, "loadingValidateAddress"))
            }
        } else if (addresses.length > 0) {
            const indexDefaultAddress = addresses.indexOf(
                addresses.find((item) => item.id === defaultAddress)
            )
            if (indexDefaultAddress !== -1) {
                dispatch(
                    changeInputOrder(
                        indexDefaultAddress + 1,
                        "addressSelectCarnet"
                    )
                )
                dispatch(
                    changeInputOrder(
                        addresses[indexDefaultAddress].firstName,
                        "firstNameShipping"
                    )
                )
                dispatch(
                    changeInputOrder(
                        addresses[indexDefaultAddress].lastName,
                        "lastNameShipping"
                    )
                )
                dispatch(
                    changeInputOrder(
                        addresses[indexDefaultAddress].company,
                        "societyShipping"
                    )
                )
                dispatch(
                    changeInputOrder(
                        addresses[indexDefaultAddress].city,
                        "cityShipping"
                    )
                )
                dispatch(
                    changeInputOrder(
                        addresses[indexDefaultAddress].street,
                        "streetShipping"
                    )
                )
                dispatch(
                    changeInputOrder(
                        addresses[indexDefaultAddress].postcode,
                        "postCodeShipping"
                    )
                )
                dispatch(
                    changeInputOrder(
                        addresses[indexDefaultAddress].countryCode,
                        "countryShipping"
                    )
                )
                dispatch(
                    changeInputOrder(
                        addresses[indexDefaultAddress].phoneNumber,
                        "telephoneShipping"
                    )
                )
                dispatch(changeInputOrder(false, "errorTelephoneShipping"))
            } else {
                dispatch(changeInputOrder(1, "addressSelectCarnet"))
                dispatch(
                    changeInputOrder(
                        addresses[0].firstName,
                        "firstNameShipping"
                    )
                )
                dispatch(
                    changeInputOrder(addresses[0].lastName, "lastNameShipping")
                )
                dispatch(
                    changeInputOrder(addresses[0].company, "societyShipping")
                )
                dispatch(changeInputOrder(addresses[0].city, "cityShipping"))
                dispatch(
                    changeInputOrder(addresses[0].street, "streetShipping")
                )
                dispatch(
                    changeInputOrder(addresses[0].postcode, "postCodeShipping")
                )
                dispatch(
                    changeInputOrder(
                        addresses[0].countryCode,
                        "countryShipping"
                    )
                )
                dispatch(
                    changeInputOrder(
                        addresses[0].phoneNumber,
                        "telephoneShipping"
                    )
                )
                dispatch(changeInputOrder(false, "errorTelephoneShipping"))
            }
        } else if (addresses.length === 0) {
            dispatch(changeInputOrder(0, "addressSelectCarnet"))
            dispatch(changeInputOrder("", "firstNameShipping"))
            dispatch(changeInputOrder("", "lastNameShipping"))
            dispatch(changeInputOrder("", "societyShipping"))
            dispatch(changeInputOrder("", "cityShipping"))
            dispatch(changeInputOrder("", "streetShipping"))
            dispatch(changeInputOrder("", "postCodeShipping"))
            dispatch(changeInputOrder("FR", "countryShipping"))
            dispatch(changeInputOrder("", "telephoneShipping"))
            dispatch(changeInputOrder(false, "errorTelephoneShipping"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartData])

    useEffect(() => {
        if (payplugUrl !== "") {
            window.location.replace(payplugUrl)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payplugUrl])



    useEffect(() => {
        if (redirectPaiement) {
            navigate("/commande/paiement")
        }
    })

    const countries = useSelector((state) => state.user.countries)
    const isBillingAddressSame = useSelector(
        (state) => state.cart.sameAddressAsShippingAddress
    )

    const lastNameShipping = useSelector((state) => state.cart.lastNameShipping)
    const firstNameShipping = useSelector(
        (state) => state.cart.firstNameShipping
    )
    const societyShipping = useSelector((state) => state.cart.societyShipping)
    const streetShipping = useSelector((state) => state.cart.streetShipping)
    const postCodeShipping = useSelector((state) => state.cart.postCodeShipping)
    const cityShipping = useSelector((state) => state.cart.cityShipping)
    const countryShipping = useSelector((state) => state.cart.countryShipping)
    const telephoneShipping = useSelector(
        (state) => state.cart.telephoneShipping
    )
    const errorTelephoneShipping = useSelector(
        (state) => state.cart.errorTelephoneShipping
    )

    const lastNameBilling = useSelector((state) => state.cart.lastNameBilling)
    const firstNameBilling = useSelector((state) => state.cart.firstNameBilling)
    const societyBilling = useSelector((state) => state.cart.societyBilling)
    const streetBilling = useSelector((state) => state.cart.streetBilling)
    const postCodeBilling = useSelector((state) => state.cart.postCodeBilling)
    const cityBilling = useSelector((state) => state.cart.cityBilling)
    const countryBilling = useSelector((state) => state.cart.countryBilling)
    const telephoneBilling = useSelector((state) => state.cart.telephoneBilling)
    const errorTelephoneBilling = useSelector(
        (state) => state.cart.errorTelephoneBilling
    )

    const addressSelectCarnet = useSelector(
        (state) => state.cart.addressSelectCarnet
    )
    const chooseDelivery = useSelector((state) => state.cart.chooseDelivery)
    const shipmentsSelected = useSelector(
        (state) => state.cart.shipmentSelected
    )

    const addressValidate = useSelector((state) => state.cart.addressValidate)
    const loadingAddresses = useSelector(
        (state) => state.user.loadingAddAddress
    )
    const loadingAddress = useSelector(
        (state) => state.cart.loadingValidateAddress
    )

    const redirectPaiementWindow = useSelector(
        (state) => state.cart.redirectPaiementWindow
    )
    const openMondialRelayWidget = useSelector(
        (state) => state.cart.openMondialRelayWidget
    )

    
    return (
        <div className="livraison-wrapper">

            {openMondialRelayWidget && (
                <MondialRelayWidget/>
            )}

            <div className="livraison-title">Livraison</div>
            {(loadingCart || loadingAddresses || loadingAddress) && <Spinner />}

            {!loadingCart && !loadingAddresses && !loadingAddress && (
                <>
                    {!chooseDelivery &&
                        (cartData.shipments.length === 0 ||
                            (!addressValidate &&
                                cartData.shipments.length > 0)) && (
                            <div className="order-address-wrapper">
                                <form
                                    action="#"
                                    onSubmit={(event) => {
                                        event.preventDefault()
                                        window.scrollTo({ top: 0 })
                                        if (
                                            telephoneShipping.match(
                                               /^[0-9]+$/g
                                            ) && (telephoneShipping.trim().length <= 15)
                                        ) {
                                            dispatch(
                                                changeInputOrder(
                                                    false,
                                                    "errorTelephoneShipping"
                                                )
                                            )
                                            dispatch(
                                                putAddAddressOrder(
                                                    cartData.tokenValue
                                                )
                                            )
                                            dispatch(
                                                changeInputOrder(
                                                    true,
                                                    "loadingCart"
                                                )
                                            )
                                            dispatch(
                                                changeInputOrder(
                                                    true,
                                                    "loadingValidateAddress"
                                                )
                                            )
                                        } else {
                                            dispatch(
                                                changeInputOrder(
                                                    true,
                                                    "errorTelephoneShipping"
                                                )
                                            )
                                        }
                                    }}>
                                    <div className="adresse">
                                        <div className="name">
                                            Adresse de livraison :
                                        </div>

                                        {addresses.length > 0 && (
                                            <div className="chose-address-select">
                                                <label>
                                                    Choissisez une adresse dans
                                                    votre carnet d'adresses :
                                                </label>
                                                <select
                                                    value={addressSelectCarnet}
                                                    onChange={(event) => {
                                                        dispatch(
                                                            changeInputOrder(
                                                                false,
                                                                "addressValidate"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                false,
                                                                "errorTelephoneShipping"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                Number(
                                                                    event.target
                                                                        .value
                                                                ),
                                                                "addressSelectCarnet"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                addresses[
                                                                    Number(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    ) - 1
                                                                ].firstName,
                                                                "firstNameShipping"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                addresses[
                                                                    Number(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    ) - 1
                                                                ].lastName,
                                                                "lastNameShipping"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                addresses[
                                                                    Number(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    ) - 1
                                                                ].company,
                                                                "societyShipping"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                addresses[
                                                                    Number(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    ) - 1
                                                                ].city,
                                                                "cityShipping"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                addresses[
                                                                    Number(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    ) - 1
                                                                ].street,
                                                                "streetShipping"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                addresses[
                                                                    Number(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    ) - 1
                                                                ].postcode,
                                                                "postCodeShipping"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                addresses[
                                                                    Number(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    ) - 1
                                                                ].countryCode,
                                                                "countryShipping"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                addresses[
                                                                    Number(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    ) - 1
                                                                ].phoneNumber,
                                                                "telephoneShipping"
                                                            )
                                                        )
                                                    }}>
                                                    <option value={0}>
                                                        -----------------------------------
                                                    </option>
                                                    {addresses.map(
                                                        (
                                                            {
                                                                id,
                                                                firstName,
                                                                lastName,
                                                                street,
                                                                postcode,
                                                                city,
                                                            },
                                                            index
                                                        ) => (
                                                            <option
                                                                key={id}
                                                                value={
                                                                    index + 1
                                                                }>
                                                                {firstName}{" "}
                                                                {lastName},{" "}
                                                                {street} ,{" "}
                                                                {postcode}{" "}
                                                                {city}{" "}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                        )}

                                        <div className="adress-formulaire">
                                            <div className="form-address-left">
                                                <InputSimple
                                                    inputName={
                                                        "lastNameShipping"
                                                    }
                                                    label={"Nom *"}
                                                    value={lastNameShipping}
                                                    type={"text"}
                                                    required={true}
                                                    onChange={(event) => {
                                                        dispatch(
                                                            changeInputOrder(
                                                                event.target
                                                                    .value,
                                                                "lastNameShipping"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                false,
                                                                "addressValidate"
                                                            )
                                                        )
                                                    }}
                                                />

                                                <InputSimple
                                                    inputName={
                                                        "firstNameShipping"
                                                    }
                                                    label={"Prénom *"}
                                                    value={firstNameShipping}
                                                    type={"text"}
                                                    required={true}
                                                    onChange={(event) => {
                                                        dispatch(
                                                            changeInputOrder(
                                                                event.target
                                                                    .value,
                                                                "firstNameShipping"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                false,
                                                                "addressValidate"
                                                            )
                                                        )
                                                    }}
                                                />
                                                <InputSimple
                                                    inputName={
                                                        "societyShipping"
                                                    }
                                                    label={"Société"}
                                                    value={
                                                        societyShipping !== null
                                                            ? societyShipping
                                                            : ""
                                                    }
                                                    type={"text"}
                                                    required={false}
                                                    onChange={(event) => {
                                                        dispatch(
                                                            changeInputOrder(
                                                                event.target
                                                                    .value,
                                                                "societyShipping"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                false,
                                                                "addressValidate"
                                                            )
                                                        )
                                                    }}
                                                />

                                                <InputSimple
                                                    inputName={"streetShipping"}
                                                    label={"Voie et numéro *"}
                                                    maxLength={35}
                                                    value={streetShipping}
                                                    type={"text"}
                                                    required={true}
                                                    onChange={(event) => {
                                                        dispatch(
                                                            changeInputOrder(
                                                                event.target
                                                                    .value,
                                                                "streetShipping"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                false,
                                                                "addressValidate"
                                                            )
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <div className="form-address-right">
                                                <InputSimple
                                                    inputName={
                                                        "postCodeShipping"
                                                    }
                                                    label={"Code postal *"}
                                                    value={postCodeShipping}
                                                    type={
                                                        countryShipping === "FR"
                                                            ? "number"
                                                            : "text"
                                                    }
                                                    required={true}
                                                    onChange={(event) => {
                                                        dispatch(
                                                            changeInputOrder(
                                                                event.target
                                                                    .value,
                                                                "postCodeShipping"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                false,
                                                                "addressValidate"
                                                            )
                                                        )
                                                    }}
                                                />

                                                <InputSimple
                                                    inputName={"cityShipping"}
                                                    label={"Ville *"}
                                                    value={cityShipping}
                                                    type={"text"}
                                                    required={true}
                                                    onChange={(event) => {
                                                        dispatch(
                                                            changeInputOrder(
                                                                event.target
                                                                    .value,
                                                                "cityShipping"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                false,
                                                                "addressValidate"
                                                            )
                                                        )
                                                    }}
                                                />
                                                <div className="input-wrapper">
                                                    <label
                                                        className="personal-label"
                                                        htmlFor="first-name">
                                                        Pays *
                                                    </label>
                                                    <select
                                                        required
                                                        value={countryShipping}
                                                        name="country"
                                                        onChange={(event) => {
                                                            dispatch(
                                                                changeInputOrder(
                                                                    event.target
                                                                        .value,
                                                                    "countryShipping"
                                                                )
                                                            )
                                                            dispatch(
                                                                changeInputOrder(
                                                                    false,
                                                                    "addressValidate"
                                                                )
                                                            )
                                                        }}>
                                                        {countries.map(
                                                            ({
                                                                name,
                                                                code,
                                                            }) => {
                                                                if (
                                                                    code !==
                                                                        "EZ" &&
                                                                    code !==
                                                                        "AC"
                                                                ) {
                                                                    return (
                                                                        <option
                                                                            key={
                                                                                code
                                                                            }
                                                                            value={
                                                                                code
                                                                            }>
                                                                            {
                                                                                name
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                                return null
                                                            }
                                                        )}
                                                    </select>
                                                </div>
                                                <InputSimple
                                                    maxLength={15}
                                                    error={
                                                        errorTelephoneShipping
                                                    }
                                                    inputName={
                                                        "telephoneShipping"
                                                    }
                                                    label={"Téléphone *"}
                                                    value={
                                                        telephoneShipping !==
                                                        null
                                                            ? telephoneShipping
                                                            : ""
                                                    }
                                                    type={"tel"}
                                                    required={true}
                                                    onChange={(event) => {
                                                        dispatch(
                                                            changeInputOrder(
                                                                false,
                                                                "errorTelephoneShipping"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                event.target
                                                                    .value,
                                                                "telephoneShipping"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                false,
                                                                "addressValidate"
                                                            )
                                                        )
                                                    }}
                                                />
                                                <div
                                                    className={`input-information ${errorTelephoneShipping ? "error" : ""}`}>
                                                    Les lettres et les symboles ne sont pas
                                                    autorisées.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="adresse">
                                        <div className="name">
                                            Adresse de facturation :
                                        </div>
                                        <InputSimple
                                            className={"checkbox"}
                                            // inputName={"defaultAddressAdd"}
                                            label={
                                                "Utiliser la même adresse que l'adresse de livraison"
                                            }
                                            value={isBillingAddressSame}
                                            checked={isBillingAddressSame}
                                            type={"checkbox"}
                                            required={false}
                                            onChange={(event) => {
                                                dispatch(
                                                    toggleBillingAddress(
                                                        !isBillingAddressSame
                                                    )
                                                )
                                                dispatch(
                                                    changeInputOrder(
                                                        false,
                                                        "addressValidate"
                                                    )
                                                )
                                            }}
                                        />

                                        {!isBillingAddressSame && (
                                            <div className="adress-formulaire">
                                                <div className="form-address-left">
                                                    <InputSimple
                                                        inputName={
                                                            "lastNameBilling"
                                                        }
                                                        label={"Nom *"}
                                                        value={lastNameBilling}
                                                        type={"text"}
                                                        required={true}
                                                        onChange={(event) => {
                                                            dispatch(
                                                                changeInputOrder(
                                                                    event.target
                                                                        .value,
                                                                    "lastNameBilling"
                                                                )
                                                            )
                                                            dispatch(
                                                                changeInputOrder(
                                                                    false,
                                                                    "addressValidate"
                                                                )
                                                            )
                                                        }}
                                                    />

                                                    <InputSimple
                                                        inputName={
                                                            "firstNameBilling"
                                                        }
                                                        label={"Prénom *"}
                                                        value={firstNameBilling}
                                                        type={"text"}
                                                        required={true}
                                                        onChange={(event) => {
                                                            dispatch(
                                                                changeInputOrder(
                                                                    event.target
                                                                        .value,
                                                                    "firstNameBilling"
                                                                )
                                                            )
                                                            dispatch(
                                                                changeInputOrder(
                                                                    false,
                                                                    "addressValidate"
                                                                )
                                                            )
                                                        }}
                                                    />
                                                    <InputSimple
                                                        inputName={
                                                            "societyBilling"
                                                        }
                                                        label={"Société"}
                                                        value={societyBilling}
                                                        type={"text"}
                                                        required={false}
                                                        onChange={(event) => {
                                                            dispatch(
                                                                changeInputOrder(
                                                                    event.target
                                                                        .value,
                                                                    "societyBilling"
                                                                )
                                                            )
                                                            dispatch(
                                                                changeInputOrder(
                                                                    false,
                                                                    "addressValidate"
                                                                )
                                                            )
                                                        }}
                                                    />

                                                    <InputSimple
                                                        inputName={
                                                            "streetShipping"
                                                        }
                                                        label={
                                                            "Voie et numéro *"
                                                        }
                                                        maxLength={35}
                                                        value={streetBilling}
                                                        type={"text"}
                                                        required={true}
                                                        onChange={(event) => {
                                                            dispatch(
                                                                changeInputOrder(
                                                                    event.target
                                                                        .value,
                                                                    "streetBilling"
                                                                )
                                                            )
                                                            dispatch(
                                                                changeInputOrder(
                                                                    false,
                                                                    "addressValidate"
                                                                )
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-address-right">
                                                    <InputSimple
                                                        inputName={
                                                            "postCodeBilling"
                                                        }
                                                        label={"Code postal *"}
                                                        value={postCodeBilling}
                                                        type={
                                                            countryShipping ===
                                                            "FR"
                                                                ? "number"
                                                                : "text"
                                                        }
                                                        required={true}
                                                        onChange={(event) => {
                                                            dispatch(
                                                                changeInputOrder(
                                                                    event.target
                                                                        .value,
                                                                    "postCodeBilling"
                                                                )
                                                            )
                                                            dispatch(
                                                                changeInputOrder(
                                                                    false,
                                                                    "addressValidate"
                                                                )
                                                            )
                                                        }}
                                                    />

                                                    <InputSimple
                                                        inputName={
                                                            "cityBilling"
                                                        }
                                                        label={"Ville *"}
                                                        value={cityBilling}
                                                        type={"text"}
                                                        required={true}
                                                        onChange={(event) => {
                                                            dispatch(
                                                                changeInputOrder(
                                                                    event.target
                                                                        .value,
                                                                    "cityBilling"
                                                                )
                                                            )
                                                            dispatch(
                                                                changeInputOrder(
                                                                    false,
                                                                    "addressValidate"
                                                                )
                                                            )
                                                        }}
                                                    />
                                                    <div className="input-wrapper">
                                                        <label
                                                            className="personal-label"
                                                            htmlFor="first-name">
                                                            Pays *
                                                        </label>
                                                        <select
                                                            required
                                                            value={
                                                                countryBilling
                                                            }
                                                            name="country"
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                dispatch(
                                                                    changeInputOrder(
                                                                        event
                                                                            .target
                                                                            .value,
                                                                        "countryBilling"
                                                                    )
                                                                )
                                                                dispatch(
                                                                    changeInputOrder(
                                                                        false,
                                                                        "addressValidate"
                                                                    )
                                                                )
                                                            }}>
                                                            {countries.map(
                                                                ({
                                                                    name,
                                                                    code,
                                                                }) => {
                                                                    if (
                                                                        code !==
                                                                            "EZ" &&
                                                                        code !==
                                                                            "AC"
                                                                    ) {
                                                                        return (
                                                                            <option
                                                                                key={
                                                                                    code
                                                                                }
                                                                                value={
                                                                                    code
                                                                                }>
                                                                                {
                                                                                    name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    }
                                                                    return null
                                                                }
                                                            )}
                                                        </select>
                                                    </div>
                                                    <InputSimple
                                                        error={
                                                            errorTelephoneBilling
                                                        }
                                                        inputName={
                                                            "telephoneBilling"
                                                        }
                                                        label={"Téléphone *"}
                                                        value={telephoneBilling}
                                                        type={"tel"}
                                                        required={true}
                                                        onChange={(event) => {
                                                            dispatch(
                                                                changeInputOrder(
                                                                    false,
                                                                    "errorTelephoneBilling"
                                                                )
                                                            )
                                                            dispatch(
                                                                changeInputOrder(
                                                                    event.target
                                                                        .value,
                                                                    "telephoneBilling"
                                                                )
                                                            )
                                                            dispatch(
                                                                changeInputOrder(
                                                                    false,
                                                                    "addressValidate"
                                                                )
                                                            )
                                                        }}
                                                    />
                                                    <div
                                                        className={`input-information ${errorTelephoneBilling ? "error" : ""}`}>
                                                        Les lettres ne sont pas
                                                        autorisées.
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {!addressValidate && (
                                        <button
                                            className="submit-button"
                                            type="submit">
                                            Continuer {">"}
                                        </button>
                                    )}
                                </form>
                            </div>
                        )}
                    {chooseDelivery && cartData.shipments.length > 0 && (
                        <div>
                            <div
                                className="return"
                                onClick={() => {
                                    dispatch(
                                        changeInputOrder(
                                            false,
                                            "addressValidate"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            false,
                                            "chooseDelivery"
                                        )
                                    )
                                }}>
                                {"< "}Retour aux adresses
                            </div>

                            <div className="shipment-address-wrapper">
                                <div>
                                    Veuillez choisir un mode de livraison :{" "}
                                </div>
                                <form
                                    onSubmit={(event) => {
                                        event.preventDefault()
                                        dispatch(
                                            changeInputOrder(
                                                true,
                                                "loadingValidateAddress"
                                            )
                                        )

                                        dispatch(
                                            changeInputOrder(
                                                true,
                                                "loadingCart"
                                            )
                                        )

                                        dispatch(
                                            patchShippingMethod(
                                                shipmentsSelected
                                            )
                                        )
                                    }}
                                    onChange={() => {
                                        dispatch(
                                            changeInputOrder(
                                                false,
                                                "redirectPaiementWindow"
                                            )
                                        )
                                    }}>
                                    {cartData.shipments[0].availableMethods.map(
                                        ({ id, configuration, name, cost, code}) => (
                                            
                                            <>
                                            <div
                                                key={id}
                                                className="shipping-item-wrapper">
                                                <label
                                                    htmlFor={id}
                                                    onClick={() => {
                                                        if(code.includes("mondial-relay") && mondialRelayId === null) {
                                                            dispatch(changeInputOrder(true, "isMondialRelayWidgetLoading"))
                                                            dispatch(changeInputOrder(true, "openMondialRelayWidget"));
                                                        } else {
                                                            dispatch(changeInputOrder(false, "openMondialRelayWidget"));
                                                        }
                                                        dispatch(
                                                            changeInputOrder(
                                                                id,
                                                                "shipmentSelected"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                false,
                                                                "addressValidate"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                false,
                                                                "redirectPaiementWindow"
                                                            )
                                                        )
                                                    }}>
                                                    {name}{" - "}{(cost/100).toString().replace(".",",") + "€"}
                                                    {/* {(
                                                        configuration.artesane
                                                            .amount / 100
                                                    )
                                                        .toString()
                                                        .replace(".", ",") +
                                                        "€"} */}
                                                </label>
                                                <input
                                                    value={id}
                                                    onChange={(event) => {
                                                        dispatch(
                                                            changeInputOrder(
                                                                event.target
                                                                    .value,
                                                                "shipmentSelected"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                false,
                                                                "redirectPaiementWindow"
                                                            )
                                                        )
                                                        dispatch(
                                                            changeInputOrder(
                                                                false,
                                                                "addressValidate"
                                                            )
                                                        )
                                                    }}
                                                    checked={
                                                        Number(
                                                            shipmentsSelected
                                                        ) === id
                                                            ? true
                                                            : false
                                                    }
                                                    name={id}
                                                    type={"radio"}
                                                />
                                            </div>
                                                    
                                            {mondialRelayId !== null && code.includes("mondial-relay") && id === shipmentsSelected && (
                                                <MondialRelaySelect/>
                                            )}

                            </>
                            )
                                    )}
                                {chooseDelivery &&
                                        !redirectPaiementWindow && (
                                            // cartData.checkoutState !==
                                            //     "payment_skipped" &&
                                            <button
                                                className="submit-button"
                                                type="submit">
                                                Valider {">"}
                                            </button>
                                        )}
                                </form>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default Livraison
