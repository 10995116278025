import "./Associations.scss"
import { useSelector } from "react-redux"
import { selectHomeUniversActifSlug } from "../../../selectors/selectors"
import LessonCardSelection from "../../Commons/Cards/LessonCardSelection/LessonCardSelection"
import AliceCarousel from "react-alice-carousel"
const Associations = () => {
    const association = useSelector((state) => state.cart.cartAssociation)
    return (
        <div className="association-wrapper">
            <div className="association-title">les indispensables</div>
            <div
                className={`caroussel-association-wrapper${association.length < 3 ? " flex" : ""}`}>
                {association.length < 3 &&
                    association.map(
                        ({
                            product_id,
                            ArtesaneAutoProductResume_url_thumbnail_big,
                            ArtesaneAutoProductResume_name,
                            ArtesaneViewProduct_teacher_name,
                            ArtesaneAutoProductResume_dure,
                            ArtesaneAutoProductResume_niveau,
                            nouveaute,
                            ArtesaneAutoProductResume_type_de_produit,
                            variants,
                            buy_once,
                            ArtesaneViewProduct_main_taxon_id,
                            ArtesaneAutoProductResume_slug,
                            giftCardAmountConfigurable,
                            giftCard,
                        }) => (
                            <div
                                key={product_id}
                                className="association-slider-card-wrapper">
                                <LessonCardSelection
                                    key={product_id}
                                    productId={product_id}
                                    shipedRequired={
                                        variants[0].shipping_required
                                    }
                                    variants={variants}
                                    urlImg={
                                        ArtesaneAutoProductResume_url_thumbnail_big
                                    }
                                    pack={
                                        ArtesaneAutoProductResume_type_de_produit ===
                                        "Packs"
                                            ? true
                                            : false
                                    }
                                    coffret={
                                        ArtesaneAutoProductResume_type_de_produit ===
                                        "Coffrets"
                                            ? true
                                            : false
                                    }
                                    parcours={
                                        ArtesaneAutoProductResume_type_de_produit ===
                                        "Parcours"
                                            ? true
                                            : false
                                    }
                                    newer={nouveaute}
                                    title={ArtesaneAutoProductResume_name}
                                    professor={ArtesaneViewProduct_teacher_name}
                                    time={ArtesaneAutoProductResume_dure}
                                    level={ArtesaneAutoProductResume_niveau}
                                    price={
                                        variants[0].nouvellePromotion > 0
                                            ? variants[0].nouveauPrix / 100
                                            : variants[0].price / 100
                                    }
                                    originalPrice={
                                        variants[0].original_price / 100
                                    }
                                    reduction={variants[0].nouvellePromotion}
                                    currentUnivers={selectHomeUniversActifSlug(
                                        ArtesaneViewProduct_main_taxon_id
                                    )}
                                    productSlug={ArtesaneAutoProductResume_slug}
                                    buyOnce={buy_once}
                                    giftCard={giftCard}
                                    giftCardAmountConfigurable={
                                        giftCardAmountConfigurable
                                    }
                                />
                            </div>
                        )
                    )}
                {association.length >= 3 && (
                    <AliceCarousel
                        infinite
                        autoPlay
                        animationDuration={1000}
                        autoPlayInterval={1000}
                        items={association.map(
                            ({
                                product_id,
                                ArtesaneAutoProductResume_url_thumbnail_big,
                                ArtesaneAutoProductResume_name,
                                ArtesaneViewProduct_teacher_name,
                                ArtesaneAutoProductResume_dure,
                                ArtesaneAutoProductResume_niveau,
                                nouveaute,
                                ArtesaneViewProduct_main_taxon_id,
                                ArtesaneAutoProductResume_type_de_produit,
                                variants,
                                ArtesaneAutoProductResume_slug,
                                buy_once,
                                giftCardAmountConfigurable,
                                giftCard,
                            }) => {
                                return (
                                    <div
                                        key={ArtesaneAutoProductResume_name}
                                        className="recommendation-slider-card-wrapper">
                                        <LessonCardSelection
                                            key={product_id}
                                            productId={product_id}
                                            shipedRequired={
                                                variants[0].shipping_required
                                            }
                                            variants={variants}
                                            urlImg={
                                                ArtesaneAutoProductResume_url_thumbnail_big
                                            }
                                            pack={
                                                ArtesaneAutoProductResume_type_de_produit ===
                                                "Packs"
                                                    ? true
                                                    : false
                                            }
                                            coffret={
                                                ArtesaneAutoProductResume_type_de_produit ===
                                                "Coffrets"
                                                    ? true
                                                    : false
                                            }
                                            parcours={
                                                ArtesaneAutoProductResume_type_de_produit ===
                                                "Parcours"
                                                    ? true
                                                    : false
                                            }
                                            newer={nouveaute}
                                            title={
                                                ArtesaneAutoProductResume_name
                                            }
                                            professor={
                                                ArtesaneViewProduct_teacher_name
                                            }
                                            time={
                                                ArtesaneAutoProductResume_dure
                                            }
                                            level={
                                                ArtesaneAutoProductResume_niveau
                                            }
                                            price={
                                                variants[0].nouvellePromotion >
                                                0
                                                    ? variants[0].nouveauPrix /
                                                      100
                                                    : variants[0].price / 100
                                            }
                                            originalPrice={
                                                variants[0].original_price / 100
                                            }
                                            reduction={
                                                variants[0].nouvellePromotion
                                            }
                                            currentUnivers={selectHomeUniversActifSlug(
                                                ArtesaneViewProduct_main_taxon_id
                                            )}
                                            productSlug={
                                                ArtesaneAutoProductResume_slug
                                            }
                                            buyOnce={buy_once}
                                            giftCard={giftCard}
                                            giftCardAmountConfigurable={
                                                giftCardAmountConfigurable
                                            }
                                        />
                                    </div>
                                )
                            }
                        )}
                        responsive={{
                            0: {
                                items: 1,
                            },
                            600: {
                                items: 2,
                            },
                            1024: {
                                items: 3,
                            },
                        }}
                    />
                )}
            </div>
        </div>
    )
}

export default Associations
