import "./Inscription.scss"
import Header from "../../Prepage/Header/Header"
import { NavLink, useNavigate } from "react-router-dom"
import InputSimple from "../../UserAccount/InputSimple/inputSimple"
import { useDispatch, useSelector } from "react-redux"
import Banner from "../../Banner/Banner"
import { useEffect } from "react"
import {
    changeInputAuthValue,
    loadingRegistration,
    postRegistration,
} from "../../../actions/authAction"

import Spinner from "../../Commons/Spinner/Spinner"
import ErrorPopup from "../../UserAccount/ErrorPopup/ErrorPopup"

const Inscription = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userConnected = useSelector((state) => state.auth.userConnected)
    const email = useSelector((state) => state.auth.emailRegistration)
    const errorEmailPattern = useSelector(
        (state) => state.auth.errorPatternEmailRegistration
    )
    const password = useSelector((state) => state.auth.passwordRegistration)
    const errorPatternPassword = useSelector(
        (state) => state.auth.errorPatternPasswordRegistration
    )
    const errorConfirmPassword = useSelector(
        (state) => state.auth.errorConfirmPasswordRegistration
    )
    const isVisiblePassword = useSelector(
        (state) => state.auth.visibilityPasswordRegistration
    )
    const isVisibleConfirmPassword = useSelector(
        (state) => state.auth.visibilityConfirmPasswordRegistration
    )
    const confirmPassword = useSelector(
        (state) => state.auth.confirmPasswordRegistration
    )
    const firstName = useSelector((state) => state.auth.firstNameRegistration)
    const lastName = useSelector((state) => state.auth.lastNameRegistration)
    const pseudo = useSelector((state) => state.auth.pseudoRegistration)
    const birthDate = useSelector((state) => state.auth.birthDateRegistration)
    const telephone = useSelector((state) => state.auth.telephoneRegistration)
    const errorTelephone = useSelector(
        (state) => state.auth.errorPatternTelephoneRegistration
    )
    const newsletter = useSelector(
        (state) => state.auth.newsletterEmailRegistration
    )
    const newsletterArtsDuFil = useSelector(
        (state) => state.auth.newsletterArtsDuFil
    )
    const newsletterArtsCulinaires = useSelector(
        (state) => state.auth.newsletterArtsCulinaires
    )
    const newsletterArtsPlastiques = useSelector(
        (state) => state.auth.newsletterArtsPlastiques
    )
    const isLoadingRegistration = useSelector(
        (state) => state.auth.loadingRegistration
    )
    const isErrorRequest = useSelector((state) => state.user.errorRequest)
    const capcha = useSelector((state) => state.auth.capchaRegistrationChecked)
    const errorCapcha = useSelector(
        (state) => state.auth.errorCapchaRegistration
    )
    const redirectToOrder = useSelector((state) => state.auth.redirectToOrder)

    useEffect(() => {
        document.title = "Artesane - Apprenez à créer | Inscription"
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | Inscription "
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://www.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "noindex")
    }, [])

    useEffect(() => {
        if (userConnected) {
            if (redirectToOrder) {
                navigate("/mon-panier")
            } else {
                navigate(-1)
            }
        }
    }, [userConnected, redirectToOrder, navigate])

    return (
        <div>
            <Banner />
            <Header />
            <div className="inscription-wrapper">
                {isLoadingRegistration && (
                    <div className="spinner-wrapper">
                        <Spinner />
                    </div>
                )}
                {isErrorRequest && <ErrorPopup />}
                <div className="left-part">
                    <div className="image-wrapper">
                        <img
                            src={
                                "https://static.artesane.com/images_react/arts_culinaires2.jpg"
                            }
                            alt="Les arts culinaires"
                        />
                    </div>
                    <div className="image-wrapper">
                        <img
                            src={
                                "https://static.artesane.com/images_react/arts_plastiques.jpg"
                            }
                            alt="Les arts plastiques"
                        />
                    </div>
                    <div className="image-wrapper">
                        <img
                            src={
                                "https://static.artesane.com/images_react/test_arts_du_fil.jpg"
                            }
                            alt="Les arts du fil"
                        />
                    </div>
                </div>
                <div className="right-part">
                    <div className="title-inscription">Créez votre compte</div>
                    <div className="form-inscriptions-wrapper">
                        <form
                            action="#"
                            onSubmit={(event) => {
                                event.preventDefault()

                                if (
                                    ((telephone.match(/[0-9]/g) ||
                                        telephone.match(/[-+!?*$@%_]/g)) &&
                                        !telephone.match(/[A-Z]/g) &&
                                        !telephone.match(/[a-z]/g)) ||
                                    telephone === ""
                                ) {
                                    dispatch(
                                        changeInputAuthValue(
                                            false,
                                            "errorPatternTelephoneRegistration"
                                        )
                                    )
                                } else {
                                    dispatch(
                                        changeInputAuthValue(
                                            true,
                                            "errorPatternTelephoneRegistration"
                                        )
                                    )
                                }

                                if (
                                    password.match(/[A-Z]/g) &&
                                    password.match(/[0-9]/g) &&
                                    password.match(/[a-z]/g) &&
                                    password.match(/[-+!?*$@%_]/g) &&
                                    password.match(/[-+!?*$@%_\w]/g) &&
                                    password.length > 8 &&
                                    password.length <= 30
                                ) {
                                    dispatch(
                                        changeInputAuthValue(
                                            false,
                                            "errorPatternPasswordRegistration"
                                        )
                                    )
                                } else {
                                    dispatch(
                                        changeInputAuthValue(
                                            true,
                                            "errorPatternPasswordRegistration"
                                        )
                                    )
                                }

                                if (password === confirmPassword) {
                                    dispatch(
                                        changeInputAuthValue(
                                            false,
                                            "errorConfirmPasswordRegistration"
                                        )
                                    )
                                } else {
                                    dispatch(
                                        changeInputAuthValue(
                                            true,
                                            "errorConfirmPasswordRegistration"
                                        )
                                    )
                                }
                                if (
                                    email.match(
                                        // eslint-disable-next-line
                                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
                                    )
                                ) {
                                    dispatch(
                                        changeInputAuthValue(
                                            false,
                                            "errorPatternEmailRegistration"
                                        )
                                    )
                                } else {
                                    dispatch(
                                        changeInputAuthValue(
                                            true,
                                            "errorPatternEmailRegistration"
                                        )
                                    )
                                }
                                if (!capcha) {
                                    dispatch(
                                        changeInputAuthValue(
                                            true,
                                            "errorCapchaRegistration"
                                        )
                                    )
                                }
                                dispatch(loadingRegistration(true))
                                dispatch(postRegistration())
                            }}>
                            <div className="parts-wrapper">
                                <div className="left-part">
                                    <InputSimple
                                        error={errorEmailPattern}
                                        inputName={"inscriptionEmail"}
                                        value={email}
                                        placeholder={"Adresse mail *"}
                                        type={"email"}
                                        required={true}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputAuthValue(
                                                    false,
                                                    "errorPatternEmailRegistration"
                                                )
                                            )
                                            dispatch(
                                                changeInputAuthValue(
                                                    event.target.value,
                                                    "emailRegistration"
                                                )
                                            )
                                        }}
                                    />
                                    <div
                                        className={`input-information ${errorEmailPattern ? "error" : ""}`}>
                                        {" "}
                                        Votre email au format :
                                        exemple@domaine.com{" "}
                                    </div>
                                    <InputSimple
                                        error={errorPatternPassword}
                                        iconVisibility={
                                            <img
                                                src={
                                                    isVisiblePassword
                                                        ? "https://static.artesane.com/images_react/oeil_barre.png"
                                                        : "https://static.artesane.com/images_react/oeil.png"
                                                }
                                                alt="oeil"
                                                onClick={() => {
                                                    dispatch(
                                                        changeInputAuthValue(
                                                            !isVisiblePassword,
                                                            "visibilityPasswordRegistration"
                                                        )
                                                    )
                                                }}
                                            />
                                        }
                                        inputName={"inscriptionPassword"}
                                        value={password}
                                        placeholder={"Mot de passe *"}
                                        type={
                                            isVisiblePassword
                                                ? "text"
                                                : "password"
                                        }
                                        required={true}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputAuthValue(
                                                    false,
                                                    "errorPatternPasswordRegistration"
                                                )
                                            )
                                            dispatch(
                                                changeInputAuthValue(
                                                    event.target.value,
                                                    "passwordRegistration"
                                                )
                                            )
                                        }}
                                    />
                                    <div
                                        className={`input-information ${errorPatternPassword ? "error" : ""}`}>
                                        {" "}
                                        8 à 30 caractères requis. Au moins une
                                        majuscule, une minuscule, un chiffre, un
                                        caractère spécial ($ @ % * + - _ ! ?){" "}
                                    </div>
                                    <InputSimple
                                        error={errorConfirmPassword}
                                        iconVisibility={
                                            <img
                                                src={
                                                    isVisibleConfirmPassword
                                                        ? "https://static.artesane.com/images_react/oeil_barre.png"
                                                        : "https://static.artesane.com/images_react/oeil.png"
                                                }
                                                alt="oeil"
                                                onClick={() => {
                                                    dispatch(
                                                        changeInputAuthValue(
                                                            !isVisibleConfirmPassword,
                                                            "visibilityConfirmPasswordRegistration"
                                                        )
                                                    )
                                                }}
                                            />
                                        }
                                        inputName={"inscriptionConfirmPassword"}
                                        value={confirmPassword}
                                        placeholder={
                                            "Confirmation du mot de passe *"
                                        }
                                        type={
                                            isVisibleConfirmPassword
                                                ? "text"
                                                : "password"
                                        }
                                        required={true}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputAuthValue(
                                                    false,
                                                    "errorConfirmPasswordRegistration"
                                                )
                                            )
                                            dispatch(
                                                changeInputAuthValue(
                                                    event.target.value,
                                                    "confirmPasswordRegistration"
                                                )
                                            )
                                        }}
                                    />
                                    <div
                                        className={`input-information ${errorConfirmPassword ? "error" : ""}`}>
                                        Correspondance des mots de passe
                                    </div>
                                    <InputSimple
                                        inputName={"inscriptionFirstName"}
                                        value={firstName}
                                        placeholder={"Prénom *"}
                                        type={"text"}
                                        required={true}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputAuthValue(
                                                    event.target.value,
                                                    "firstNameRegistration"
                                                )
                                            )
                                        }}
                                    />
                                    <InputSimple
                                        inputName={"inscriptionLastName"}
                                        value={lastName}
                                        placeholder={"Nom *"}
                                        type={"text"}
                                        required={true}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputAuthValue(
                                                    event.target.value,
                                                    "lastNameRegistration"
                                                )
                                            )
                                        }}
                                    />
                                    <InputSimple
                                        inputName={"inscriptionPseudo"}
                                        value={pseudo}
                                        placeholder={"Pseudo *"}
                                        type={"text"}
                                        required={true}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputAuthValue(
                                                    event.target.value,
                                                    "pseudoRegistration"
                                                )
                                            )
                                        }}
                                    />
                                    <InputSimple
                                        inputName={"inscriptionBirthDate"}
                                        value={birthDate}
                                        placeholder={"Date de naissance"}
                                        dataPlaceholder={"Date de naissance"}
                                        type={"date"}
                                        required={false}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputAuthValue(
                                                    event.target.value,
                                                    "birthDateRegistration"
                                                )
                                            )
                                        }}
                                    />

                                    <InputSimple
                                        error={errorTelephone}
                                        inputName={"inscriptionTelephone"}
                                        value={telephone}
                                        placeholder={"Téléphone"}
                                        type={"tel"}
                                        required={false}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputAuthValue(
                                                    event.target.value,
                                                    "telephoneRegistration"
                                                )
                                            )
                                        }}
                                    />
                                    <div
                                        className={`input-information ${errorTelephone ? "error" : ""}`}>
                                        {" "}
                                        Chiffres et caractères spéciaux
                                        autorisés
                                    </div>
                                    <InputSimple
                                        className={"checkbox"}
                                        label={"Je m'inscris à la newsletter"}
                                        inputName={"inscriptionNewsletter"}
                                        value={newsletter}
                                        type={"checkbox"}
                                        required={false}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputAuthValue(
                                                    false,
                                                    "errorPatternTelephoneRegistration"
                                                )
                                            )
                                            dispatch(
                                                changeInputAuthValue(
                                                    !newsletter,
                                                    "newsletterEmailRegistration"
                                                )
                                            )
                                        }}
                                    />
                                </div>
                                <div className="right-part">
                                    <div className="under-title">
                                        {" "}
                                        Je suis interessé par
                                    </div>
                                    <InputSimple
                                        className={"checkbox"}
                                        label={"les Arts du fil"}
                                        inputName={"taxon"}
                                        value={newsletterArtsDuFil}
                                        type={"checkbox"}
                                        required={false}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputAuthValue(
                                                    !newsletterArtsDuFil,
                                                    "newsletterArtsDuFil"
                                                )
                                            )
                                        }}
                                    />
                                    <InputSimple
                                        className={"checkbox"}
                                        label={"les Arts culinaires"}
                                        inputName={"taxon"}
                                        value={newsletterArtsCulinaires}
                                        type={"checkbox"}
                                        required={false}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputAuthValue(
                                                    !newsletterArtsCulinaires,
                                                    "newsletterArtsCulinaires"
                                                )
                                            )
                                        }}
                                    />
                                    <InputSimple
                                        className={"checkbox"}
                                        label={"les Arts plastiques"}
                                        inputName={"taxon"}
                                        value={newsletterArtsPlastiques}
                                        type={"checkbox"}
                                        required={false}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputAuthValue(
                                                    !newsletterArtsPlastiques,
                                                    "newsletterArtsPlastiques"
                                                )
                                            )
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="inscription-text">
                                En créant votre compte, vous acceptez
                                qu'Artesane traite vos données afin de vous
                                permettre de consulter et gérer votre espace
                                personnel, de faciliter votre navigation et vos
                                commandes, de vous adresser des enquêtes de
                                satisfaction, et d’assurer le bon fonctionnement
                                technique du site ou de l’application
                            </div>
                            <div className="captcha-wrapper">
                                <InputSimple
                                    className={`checkbox capcha ${capcha ? `validate` : ``} ${errorCapcha ? `error` : ""}`}
                                    label={"Je ne suis pas un robot"}
                                    inputName={"robot"}
                                    value={capcha}
                                    checked={capcha}
                                    type={"checkbox"}
                                    required={false}
                                    onChange={(event) => {
                                        dispatch(
                                            changeInputAuthValue(
                                                false,
                                                "errorCapchaRegistration"
                                            )
                                        )
                                        dispatch(
                                            changeInputAuthValue(
                                                !capcha,
                                                "capchaRegistrationChecked"
                                            )
                                        )
                                    }}
                                />
                                <img
                                    src={
                                        "https://static.artesane.com/images_react/captcha.png"
                                    }
                                    alt="captcha"
                                />
                            </div>

                            <button type="submit">
                                je crée mon compte {">"}
                            </button>
                        </form>
                    </div>
                    <div className="new-wrapper">
                        <div className="uppercase">Déjà un compte ? </div>
                        <div>
                            <NavLink to={"/connexion"}>
                                Je me connecte {">"}
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inscription
