import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, useLocation, Navigate } from "react-router-dom"
import { redirectToOrder } from "../../actions/authAction"
import Header from "./Header/Header"
import TotalOrder from "../Cart/TotalOrder/TotalOrder"
import Banner from "../Banner/Banner"
import "./Order.scss"

const Order = () => {
    const dispatch = useDispatch()
    const userConnected = useSelector((state) => state.auth.userConnected)
    const cartData = useSelector((state) => state.cart.cartData)

    const { pathname } = useLocation()

    useEffect(() => {
        if (userConnected) {
            dispatch(redirectToOrder(false))
        }
    }, [dispatch, userConnected])

    return (
        <div>
            <Banner />
            <Header />
            <div className="order-wrapper">
                {(pathname === "/commande" || pathname === "/commande/") && (
                    <Navigate to={"livraison"} replace />
                )}
                {Object.keys(cartData).length > 0 && (
                    <TotalOrder
                        link={"#"}
                        totalTTC={cartData.items_total}
                        adjustments={cartData.adjustments_total}
                        total={cartData.total}
                    />
                )}

                <Outlet />
            </div>
        </div>
    )
}
export default Order
