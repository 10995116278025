import "./Cart.scss"
import Header from "../Prepage/Header/Header"
import ItemCard from "./ItemCard/ItemCard"
import TotalOrder from "./TotalOrder/TotalOrder"
import EmptyPage from "../Commons/EmptyPage/EmptyPage"
import { useSelector, useDispatch } from "react-redux"
import { changeInputOrder } from "../../actions/cartAction"
import Reinsurances from "./Reinssurances/Reinssurances"
import Associations from "./Associations/Associations"
import { useEffect } from "react"
import Spinner from "../Commons/Spinner/Spinner"
import Banner from "../Banner/Banner"

const Cart = () => {
    const cartData = useSelector((state) => state.cart.cartData)
    const association = useSelector((state) => state.cart.cartAssociation)
    const loadingCart = useSelector((state) => state.cart.loadingCart)
    const loadingGiftcards = useSelector((state) => state.user.loadingGiftcards)
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(changeInputOrder(false, "addressValidate"))

        document.title = "Artesane - Apprenez à créer | Mon panier"
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | Mon panier"
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://www.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "noindex")
    }, [dispatch])

    const openMondialRelayWidget = useSelector(
        (state) => state.cart.openMondialRelayWidget
    )

    
    return (
        <div>
            <Banner />
            <Header />
            {(loadingCart || loadingGiftcards) && <Spinner />}
            {!loadingCart && !loadingGiftcards && (
                <div className="cart-wrapper">
                    <div className="cart-title">Mon panier</div>
                    {Object.keys(cartData).length < 1 && (
                        <EmptyPage
                            firstPart={"Votre panier est actuellement vide."}
                            secondPartOne={"En panne d’inspiration ? "}
                            secondPartTwo={
                                "Retrouvez nos meilleurs cours ici !"
                            }
                            urlSecondPart={"/arts-du-fil/catalogue/tout/1"}
                            thirdPartOne={
                                "Vous avez déjà ajouté des articles à votre panier?"
                            }
                            thirdPartTwo={"Connectez-vous pour les retrouver."}
                            urlThirdPart={"/connexion"}
                        />
                    )}
                    {Object.keys(cartData).length > 0 &&
                        cartData.items.length > 0 && (
                            <div className="card-and-total-order-wrapper">
                                <div className="item-cards-wrapper">
                                    {cartData.items.map(
                                        (
                                            {
                                                variant,
                                                unitPrice,
                                                originalUnitPrice,
                                                slug,
                                                gift,
                                                id,
                                                product,
                                                originalPrice,
                                                quantity,
                                                subtotal,
                                                total,
                                                productName,
                                            },
                                            index
                                        ) => (
                                            <ItemCard
                                                gift={gift}
                                                subtotal={subtotal}
                                                unitPrice={unitPrice}
                                                originalUnitPrice={
                                                    originalUnitPrice
                                                }
                                                universeCode={
                                                    product.universes.length > 0 ? product.universes[0].code : 'undefined'
                                                }
                                                productSlug={product.slug}
                                                index={index}
                                                dateUpdate={""}
                                                shippingRequired={
                                                    variant.shippingRequired
                                                }
                                                key={id}
                                                id={id}
                                                variant={variant}
                                                productName={productName}
                                                productId={product.id}
                                                buyOnce={product.buy_once}
                                                quantity={quantity}
                                                priceUnit={unitPrice}
                                                originalPrice={originalPrice}
                                                total={total}
                                                urlImg={
                                                    product.mainImage !==
                                                        null &&
                                                    product.mainImage !==
                                                        undefined
                                                        ? product.mainImage
                                                              .thumbnails !== undefined ? product.mainImage.thumbnails.carousel : product.mainImage.path
                                                        : ""
                                                }
                                            />
                                        )
                                    )}
                                </div>
                                <TotalOrder
                                    link={"/commande/livraison"}
                                    total={cartData.total}
                                />
                            </div>
                        )}
                    {Object.keys(cartData).length > 0 &&
                        cartData.items.length < 1 && (
                            <EmptyPage
                                firstPart={
                                    "Votre panier est actuellement vide."
                                }
                                secondPartOne={"En panne d’inspiration ? "}
                                secondPartTwo={
                                    "Retrouvez nos meilleurs cours ici !"
                                }
                                urlSecondPart={"/arts-du-fil/catalogue/tout/1"}
                                thirdPartOne={
                                    "Vous avez déjà ajouté des articles à votre panier?"
                                }
                                thirdPartTwo={
                                    "Connectez-vous pour les retrouver."
                                }
                                urlThirdPart={"/connexion"}
                            />
                        )}
                    <Reinsurances />
                    {association.length > 0 && <Associations />}
                </div>
            )}
        </div>
    )
}

export default Cart
