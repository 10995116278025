import { useDispatch, useSelector } from "react-redux"
import "./OneySimulation.scss"
import { changeInputOrder } from "../../../../actions/cartAction"

const OneySimulation = () => {
    const dispatch = useDispatch()
    const oneySimulationPopup = useSelector(
        (state) => state.cart.oneySimulationPopup
    )
    const oneySimulation = useSelector((state) => state.cart.oneySimulation)
    return (
        <div className="oney-simulation-wrapper">
            <div
                className="oney-wrapper"
                onClick={() => {
                    dispatch(changeInputOrder(true, "oneySimulationPopup"))
                }}>
                <div>OU PAYER EN</div>
                <img
                    src="https://static.artesane.com/images_react/3x4x.svg"
                    alt="Payer avec Oney en 3 ou 4 fois"
                />
            </div>
            {oneySimulationPopup && (
                <div className="oney-popin-wrapper">
                    <span
                        onClick={() => {
                            dispatch(
                                changeInputOrder(false, "oneySimulationPopup")
                            )
                        }}
                        className="close-oney">
                        X
                    </span>
                    <div className="oney-popin-content">
                        <img
                            className="oney"
                            src="https://static.artesane.com/images_react/logo.svg"
                            alt="logo oney"
                        />
                        <p className="oney-title">Paiement</p>
                        <p className="oney-title">Par carte bancaire</p>
                        <section className="section_with_fees">
                            <div>
                                <img
                                    src="https://static.artesane.com/images_react/3x.svg"
                                    alt="paiement en 3x"
                                />
                            </div>
                            <div>
                                <p>
                                    <strong>
                                        Apport :{" "}
                                        {(
                                            oneySimulation.x3_with_fees
                                                .down_payment_amount / 100
                                        )
                                            .toString()
                                            .replace(".", ",")}{" "}
                                        €
                                        <br />+ 2 mensualités de :{" "}
                                        {(
                                            oneySimulation.x3_with_fees
                                                .installments[0].amount / 100
                                        )
                                            .toString()
                                            .replace(".", ",")}{" "}
                                        €
                                    </strong>
                                    <br />
                                    Dont coût de financement :
                                    {(
                                        oneySimulation.x3_with_fees.total_cost /
                                        100
                                    )
                                        .toString()
                                        .replace(".", ",")}{" "}
                                    € TAEG :{" "}
                                    {oneySimulation.x3_with_fees.effective_annual_percentage_rate
                                        .toString()
                                        .replace(".", ",")}
                                    %
                                </p>
                            </div>
                        </section>
                        <section className="section_with_fees">
                            <div>
                                <img
                                    src="https://static.artesane.com/images_react/4x.svg"
                                    alt="paiement en 3x"
                                />
                            </div>
                            <div>
                                <p>
                                    <strong>
                                        Apport :{" "}
                                        {(
                                            oneySimulation.x4_with_fees
                                                .down_payment_amount / 100
                                        )
                                            .toString()
                                            .replace(".", ",")}{" "}
                                        €
                                        <br />+ 3 mensualités de :{" "}
                                        {(
                                            oneySimulation.x4_with_fees
                                                .installments[0].amount / 100
                                        )
                                            .toString()
                                            .replace(".", ",")}{" "}
                                        €
                                    </strong>
                                    <br />
                                    Dont coût de financement :
                                    {(
                                        oneySimulation.x4_with_fees.total_cost /
                                        100
                                    )
                                        .toString()
                                        .replace(".", ",")}{" "}
                                    € TAEG :{" "}
                                    {oneySimulation.x4_with_fees.effective_annual_percentage_rate
                                        .toString()
                                        .replace(".", ",")}
                                    %
                                </p>
                            </div>
                        </section>
                        <div className="oney-popin-footer">
                            <p>
                                Offre de financement avec apport obligatoire,
                                réservée aux particuliers et valable pour tout
                                achat de 100,00 € à 3 000,00 €. Sous réserve
                                d’acceptation par Oney Bank. Vous disposez d’un
                                délai de 14 jours pour renoncer à votre crédit.
                                Oney Bank - SA au capital de 50 741 215€ - 40
                                Avenue de Flandre 59 170 Croix - 546 380 197 RCS
                                Lille Métro- pole - n° Orias 07 023 261
                                www.orias.fr. Correspondance : CS 60 006 - 59895
                                Lille Cedex - www.oney.fr
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default OneySimulation
