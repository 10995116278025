import { useDispatch, useSelector } from "react-redux"
import "./ChoosePayment.scss"
import {
    changeInputOrder,
    patchPaymentMethod,
} from "../../../../actions/cartAction"
import OneyChoose from "./OneyChoose/OneyChoose"

const ChoosePayment = () => {
    const payments = useSelector((state) => state.cart.cartData.payments)
    const selectPaymentCode = useSelector(
        (state) => state.cart.selectPaymentCode
    )
    const validatePayment = useSelector((state) => state.cart.validatePayment)
    const cartData = useSelector((state) => state.cart.cartData)
    const oneyActive = useSelector((state) => state.cart.oneyActive)

    const dispatch = useDispatch()
    return (
        <div className="shipment-address-wrapper">
            <div>Veuillez choisir un mode de paiement : </div>
            <form
                onSubmit={(event) => {
                    event.preventDefault()
                    dispatch(changeInputOrder(true, "loadingAddPayment"))
                    dispatch(
                        patchPaymentMethod(
                            cartData.tokenValue,
                            cartData.payments[cartData.payments.length - 1].id,
                            selectPaymentCode,
                            oneyActive
                        )
                    )
                }}>
                {payments[cartData.payments.length - 1].availableMethods.map(
                    ({ id, description, name, code }) => (
                        <div key={id} className="payment-item-wrapper">
                            <input
                                value={code}
                                onChange={(event) => {
                                    if (
                                        event.target.value === "oney-by-payplug"
                                    ) {
                                        dispatch(
                                            changeInputOrder(
                                                true,
                                                "oneyChoosePayment"
                                            )
                                        )
                                    } else {
                                        dispatch(
                                            changeInputOrder(
                                                false,
                                                "oneyChoosePayment"
                                            )
                                        )
                                    }
                                    dispatch(
                                        changeInputOrder(
                                            event.target.value,
                                            "selectPaymentCode"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            false,
                                            "validateChoosePayment"
                                        )
                                    )
                                }}
                                checked={
                                    selectPaymentCode === code ? true : false
                                }
                                name={id}
                                type={"radio"}
                            />

                            {code === "oney-by-payplug" && (
                                <>
                                    <div
                                        className="payment-item oney"
                                        onClick={() => {
                                            dispatch(
                                                changeInputOrder(
                                                    code,
                                                    "selectPaymentCode"
                                                )
                                            )
                                            dispatch(
                                                changeInputOrder(
                                                    false,
                                                    "validateChoosePayment"
                                                )
                                            )
                                        }}>
                                        <img
                                            className="oney-logo"
                                            src="https://static.artesane.com/images_react/3x4x.svg"
                                            alt="payer avec Oney"
                                            onClick={() => {
                                                dispatch(
                                                    changeInputOrder(
                                                        "oney",
                                                        "selectPaymentCode"
                                                    )
                                                )
                                                dispatch(
                                                    changeInputOrder(
                                                        false,
                                                        "validatePayment"
                                                    )
                                                )
                                                dispatch(
                                                    changeInputOrder(
                                                        true,
                                                        "oneyChoosePayment"
                                                    )
                                                )
                                            }}
                                        />
                                    </div>
                                    {selectPaymentCode ===
                                        "oney-by-payplug" && <OneyChoose />}
                                </>
                            )}
                            {code === "payplug" && (
                                <div
                                    onClick={() => {
                                        dispatch(
                                            changeInputOrder(
                                                false,
                                                "oneyChoosePayment"
                                            )
                                        )
                                        dispatch(
                                            changeInputOrder(
                                                code,
                                                "selectPaymentCode"
                                            )
                                        )
                                        dispatch(
                                            changeInputOrder(
                                                false,
                                                "validatePayment"
                                            )
                                        )
                                    }}
                                    className="payment-item">
                                    <label
                                        htmlFor={id}
                                        onChange={() => {
                                            dispatch(
                                                changeInputOrder(
                                                    code,
                                                    "selectPaymentCode"
                                                )
                                            )
                                            dispatch(
                                                changeInputOrder(
                                                    false,
                                                    "validatePayment"
                                                )
                                            )
                                        }}>
                                        <img
                                            className="cb"
                                            src="https://static.artesane.com/images_react/cb.jpeg"
                                            alt="carte bancaire"
                                        />
                                        {name}
                                        <div className="payment-description">
                                            {description}
                                        </div>
                                    </label>
                                </div>
                            )}
                        </div>
                    )
                )}
                {!validatePayment && (
                    <button className="submit-button" type="submit">
                        Valider {">"}
                    </button>
                )}

                {/* )} */}
            </form>
        </div>
    )
}

export default ChoosePayment
