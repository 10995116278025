import "./Connexion.scss"
import Header from "../../Prepage/Header/Header"
import {
    changeInputAuthValue,
    visibilityAuthPassword,
    loadingConnexion,
    postConnexion,
    errorConnexion,
} from "../../../actions/authAction"
import { useDispatch, useSelector } from "react-redux"
import InputSimple from "../../UserAccount/InputSimple/inputSimple"
import { NavLink, useNavigate } from "react-router-dom"
import Spinner from "../../Commons/Spinner/Spinner"
import { useEffect } from "react"
import Banner from "../../Banner/Banner"

const Connexion = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const password = useSelector((state) => state.auth.password)
    const email = useSelector((state) => state.auth.email)
    const isVisiblePassword = useSelector(
        (state) => state.auth.visibilityPasswordConnexion
    )
    const isErrorConnexion = useSelector((state) => state.auth.errorConnexion)
    const stayConnected = useSelector((state) => state.auth.stayConnected)
    const isLoadingConnexion = useSelector(
        (state) => state.auth.loadingConnexion
    )
    const userConnected = useSelector((state) => state.auth.userConnected)
    const redirectToOrder = useSelector((state) => state.auth.redirectToOrder)
    const capcha = useSelector((state) => state.auth.capchaConnexionChecked)
    const errorCapcha = useSelector((state) => state.auth.errorCapchaConnexion)
    const redirectAfterChangePassword = useSelector(
        (state) => state.auth.redirectAfterChangePassword
    )

    useEffect(() => {
        document.title = "Connexion - Artesane"
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | Connexion "
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://www.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "noindex")
    }, [])

    useEffect(() => {
        if (userConnected) {
            if (redirectToOrder) {
                navigate("/mon-panier")
            } else {
                if (
                    window.history.state &&
                    window.history.state.idx > 1 &&
                    !redirectAfterChangePassword
                ) {
                    navigate(-1)
                } else if (
                    window.history.state &&
                    window.history.state.idx > 5 &&
                    redirectAfterChangePassword
                ) {
                    navigate(-4)

                    dispatch(
                        changeInputAuthValue(
                            false,
                            "redirectAfterChangePassword"
                        )
                    )
                } else {
                    navigate("/mon-compte/mes-commandes", { replace: true })
                    dispatch(
                        changeInputAuthValue(
                            false,
                            "redirectAfterChangePassword"
                        )
                    )
                }
            }
        }
    }, [
        userConnected,
        redirectToOrder,
        redirectAfterChangePassword,
        dispatch,
        navigate,
    ])
    return (
        <div>
            <Banner />
            <Header />
            <div className="connexion-wrapper">
                {isLoadingConnexion && (
                    <div className="connexion-spinner">
                        <Spinner />
                    </div>
                )}

                <div className="connexion-title">Connexion</div>

                <form
                    className="connexion-form"
                    action="#"
                    onSubmit={(event) => {
                        event.preventDefault()
                        if (capcha) {
                            dispatch(loadingConnexion(true))
                            dispatch(
                                postConnexion(password, email, stayConnected)
                            )
                        } else {
                            dispatch(
                                changeInputAuthValue(
                                    true,
                                    "errorCapchaConnexion"
                                )
                            )
                        }
                    }}>
                    <InputSimple
                        error={isErrorConnexion}
                        inputName={"emailConnexion"}
                        placeholder={"Adresse mail"}
                        value={email}
                        type={"email"}
                        required={true}
                        onChange={(event) => {
                            dispatch(errorConnexion(false))
                            dispatch(
                                changeInputAuthValue(
                                    event.target.value,
                                    "email"
                                )
                            )
                        }}
                    />

                    <InputSimple
                        errorMessage={
                            "Oups ! La connexion a échoué. Veuillez vérifier votre e-mail et votre mot de passe."
                        }
                        error={isErrorConnexion}
                        iconVisibility={
                            <img
                                src={
                                    isVisiblePassword
                                        ? "https://static.artesane.com/images_react/oeil_barre.png"
                                        : "https://static.artesane.com/images_react/oeil.png"
                                }
                                alt="oeil"
                                onClick={() => {
                                    dispatch(
                                        visibilityAuthPassword(
                                            !isVisiblePassword
                                        )
                                    )
                                }}
                            />
                        }
                        placeholder={"Mot de passe"}
                        inputName={"password"}
                        value={password}
                        type={isVisiblePassword ? "text" : "password"}
                        required={true}
                        onChange={(event) => {
                            dispatch(errorConnexion(false))
                            dispatch(
                                changeInputAuthValue(
                                    event.target.value,
                                    "password"
                                )
                            )
                        }}
                    />
                    {/* <InputSimple
                                className={"checkbox"}
                                inputName={"stayConnected"}
                                label={"Rester connecté"}
                                checked={stayConnected}
                                type={"checkbox"}
                                required={false}
                                onChange={(event) => {
                                    dispatch(changeInputAuthValue(!stayConnected, "stayConnected"));
                                }}
                            /> */}
                    <div className="captcha-wrapper">
                        <InputSimple
                            className={`checkbox capcha ${capcha ? `validate` : ``} ${errorCapcha ? `error` : ""}`}
                            label={"Je ne suis pas un robot"}
                            inputName={"robot"}
                            value={capcha}
                            checked={capcha}
                            type={"checkbox"}
                            required={false}
                            onChange={(event) => {
                                dispatch(
                                    changeInputAuthValue(
                                        false,
                                        "errorCapchaConnexion"
                                    )
                                )
                                dispatch(
                                    changeInputAuthValue(
                                        !capcha,
                                        "capchaConnexionChecked"
                                    )
                                )
                            }}
                        />
                        <img
                            src={
                                "https://static.artesane.com/images_react/captcha.png"
                            }
                            alt="captcha"
                        />
                    </div>
                    <div className="forget-password">
                        <NavLink to={"/mot-de-passe-oublie"}>
                            Mot de passe oublié ?
                        </NavLink>
                    </div>
                    <button type="submit">Connexion {">"}</button>
                </form>

                <div className="new-wrapper">
                    <div className="uppercase">Nouvel utilisateur ? </div>
                    <div>
                        <NavLink to={"/inscription"}>
                            Je crée mon compte {">"}
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Connexion
