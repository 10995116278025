import "./Reinssurances.scss"

const Reinsurances = () => {
    return (
        <div className="reinssurances-wrapper">
            <div className="reinssurances-items">
                <img
                    src={
                        "https://static.artesane.com/images_react/picto-prepage3.png"
                    }
                    alt="player"
                />{" "}
                <div>Accès immédiat</div>
                <div>à votre cours</div>
            </div>
            <div className="reinssurances-items">
                <img
                    src={"https://static.artesane.com/images_react/time.png"}
                    alt="horloge"
                />
                <div>Cours disponible</div>
                <div>à vie</div>
            </div>
            <div className="reinssurances-items">
                <img
                    src={"https://static.artesane.com/images_react/camion.png"}
                    alt="Camion"
                />
                <div>Livraison des coffrets</div>
                <div>en 4 à 5 jours ouvrés</div>
            </div>
        </div>
    )
}

export default Reinsurances
