import { useDispatch, useSelector } from 'react-redux';
import './MondialRelaySelect.scss';
import { changeInputOrder } from '../../../../actions/cartAction';

const MondialRelaySelect = () => {

    const dispatch = useDispatch();
    const mondialRelayName = useSelector(
        (state) => state.cart.mondialRelayName
      )

      const mondialRelayStreet = useSelector(
        (state) => state.cart.mondialRelayStreet
      )
      const mondialRelayPostCode = useSelector(
        (state) => state.cart.mondialRelayPostCode
      )
      const mondialRelayCity = useSelector(
        (state) => state.cart.mondialRelayCity
      )
      const mondialRelayCountryCode = useSelector(
        (state) => state.cart.mondialRelayCountryCode
      )
    return (
        <div className="mondial-relay-adresse">
            <div className='top-container'>
            <img src="https://upload.wikimedia.org/wikipedia/fr/0/09/Logo_Mondial_Relay_-_2022.svg" alt="mondial-relay" />
                       < div
                       className='modifier'
                       onClick={() => {
                        dispatch(changeInputOrder(true, "isMondialRelayWidgetLoading"))
                        dispatch(changeInputOrder(true, "openMondialRelayWidget"));
                       }}
                       >modifier</div>    
            </div>
                         
        <div className='mr-name'>{mondialRelayName}</div>
        <div> {mondialRelayStreet}</div>
        <div> {mondialRelayPostCode} {mondialRelayCity} {mondialRelayCountryCode}</div>
    </div>
    );
}

export default MondialRelaySelect;