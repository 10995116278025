import "./TotalOrder.scss"
import Spinner from "../../Commons/Spinner/Spinner"
import { NavLink } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { redirectToOrder } from "../../../actions/authAction"
import {
    fetchPaymentUrl,
    changeInputOrder,
    postCouponCode,
    postGiftCardCart,
    deleteGiftCardCart,
    fetchOneySimulation,
    patchPaymentMethod,
} from "../../../actions/cartAction"
import { useLocation } from "react-router-dom"
import { useEffect } from "react"
import OneySimulation from "./OneySimulation/OneySimulation"
import { filterGiftcards } from "../../../selectors/selectors"

const TotalOrder = ({ link, total }) => {
    const dispatch = useDispatch()

    const { pathname } = useLocation()
    const cartData = useSelector((state) => state.cart.cartData)
    const addressValidate = useSelector((state) => state.cart.addressValidate)
    const couponCode = useSelector((state) => state.cart.couponCode)
    const userConnected = useSelector((state) => state.auth.userConnected)

    const orderAdjustments = useSelector((state) => state.cart.orderAdjustments)
    const shipping = orderAdjustments.find(
        (element) => element.type === "shipping"
    )
    const loadingTotalOrder = useSelector(
        (state) => state.cart.loadingTotalOrder
    )
    const messageRetourCoupon = useSelector(
        (state) => state.cart.messageRetourCoupon
    )
    const giftcards = useSelector((state) => state.user.giftcards)
    const giftCardSelected = useSelector((state) => state.cart.giftCardSelected)
    const loadingGiftcards = useSelector((state) => state.user.loadingGiftcards)
    const oneySimulationLoading = useSelector(
        (state) => state.cart.oneySimulationLoading
    )

    const choosePayment = useSelector((state) => state.cart.choosePayment)
    const validatePayment = useSelector((state) => state.cart.validatePayment)
    const redirectPaiementWindow = useSelector(
        (state) => state.cart.redirectPaiementWindow
    )

    useEffect(() => {
        dispatch(fetchOneySimulation(cartData.tokenValue))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    return (
        <div className="total-order-wrapper">
            {(loadingTotalOrder || loadingGiftcards) && (
                <div className="spinner-wrapper">
                    <Spinner />
                </div>
            )}

            {((!pathname.includes("livraison") &&
                !pathname.includes("paiement")) ||
                (addressValidate &&
                    (!choosePayment || redirectPaiementWindow) &&
                    (((redirectPaiementWindow || validatePayment) &&
                        cartData.checkoutState !== "payment_skipped") ||
                        cartData.checkoutState === "payment_skipped"))) && (
                <NavLink to={link}>
                    <div
                        className="order-button"
                        onClick={() => {
                            dispatch(redirectToOrder(true))
                            if (
                                (pathname.includes("livraison") ||
                                    pathname.includes("paiement")) &&
                                cartData.shippingAddress !== undefined &&
                                ((validatePayment &&
                                    cartData.checkoutState !==
                                        "payment_skipped") ||
                                    (!validatePayment &&
                                        cartData.checkoutState ===
                                            "payment_skipped"))
                            ) {
                                dispatch(
                                    changeInputOrder(
                                        true,
                                        "loadingValidateAddress"
                                    )
                                )
                                dispatch(
                                    fetchPaymentUrl(
                                        cartData.payments.length > 0
                                            ? cartData.payments[cartData.payments.length - 1].id
                                            : 0,
                                        cartData.tokenValue,
                                        cartData.checkoutState
                                    )
                                )
                            } else if (
                                (pathname.includes("livraison") ||
                                    pathname.includes("paiement")) &&
                                cartData.shippingAddress !== undefined &&
                                redirectPaiementWindow &&
                                (cartData.checkoutState ===
                                    "shipping_selected" ||
                                    cartData.checkoutState ===
                                        "shipping_skipped")
                            ) {
                                dispatch(
                                    changeInputOrder(
                                        true,
                                        "loadingValidateAddress"
                                    )
                                )

                                dispatch(
                                    patchPaymentMethod(
                                        cartData.tokenValue,
                                        cartData.payments[cartData.payments.length - 1].id,
                                        cartData.payments[cartData.payments.length - 1].availableMethods[0]
                                            .code,
                                        ""
                                    )
                                )
                            }
                        }}>
                        {pathname.includes("livraison") ||
                        pathname.includes("paiement")
                            ? `Payer >`
                            : ""}
                        {!pathname.includes("livraison") &&
                        !pathname.includes("paiement")
                            ? `Commander >`
                            : ""}
                    </div>
                </NavLink>
            )}
            {pathname.includes("livraison") && (
                <>
                    <div className="total-order-title">
                        Recapitulatif commande
                    </div>
                    <div>
                        {cartData.items.map(
                            ({ productName, quantity, unitPrice }) => (
                                <div key={productName} className="price">
                                    <span> {productName} </span>
                                    <span>
                                        {" "}
                                        <span className="num-font">
                                            {(unitPrice / 100)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                        </span>{" "}
                                        €
                                    </span>
                                    <span> x {quantity}</span>
                                </div>
                            )
                        )}
                    </div>
                </>
            )}
            <div className="total-order-title">total commande</div>
            <div className="price">
                {" Total : "}
                <span className="num-font">
                    {(
                        (cartData.items.length > 1
                            ? cartData.items.reduce((a, b) => a + b.subtotal, 0)
                            : cartData.items[0].subtotal) / 100
                    )
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")}
                </span>{" "}
                € TTC
            </div>
            {cartData.orderPromotionTotal !== 0 && (
                <div className="price">
                    Réduction :{" "}
                    <span className="num-font">
                        {(cartData.orderPromotionTotal / 100)
                            .toFixed(2)
                            .replace(".", ",")}
                    </span>{" "}
                    €
                </div>
            )}
            {cartData.giftCards.length > 0 && (
                <div className="price">
                    <div>Montant des cartes cadeaux :</div>
                    {/* {((cartData.adjustments.filter(item => item.type.includes('gift_card')) ? cartData.adjustments.filter(item => item.type.includes('gift_card')).amount : 0 )/ 100)
                                .toFixed(2)
                                .replace(".", ",")}
                             */}
                    {cartData.adjustments.filter((item) =>
                        item.type.includes("gift_card")
                    ).length > 0 &&
                        cartData.adjustments
                            .filter((item) => item.type.includes("gift_card"))
                            .map((item) => (
                                <div className="num-font">
                                    -{" "}
                                    {Math.abs(item.amount / 100)
                                        .toFixed(2)
                                        .toString()
                                        .replace(".", ",")}{" "}
                                    €
                                </div>
                            ))}
                </div>
            )}
            <div className="taxes">
                Total des taxes incluses :{" "}
                <span className="num-font">
                    {(cartData.taxIncludedTotal / 100)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")}
                </span>{" "}
                €
            </div>

            {shipping &&
                cartData.shipping_address_id !== null &&
                addressValidate &&
                !pathname.includes("panier") && (
                    <>
                        <div className="delivery validate">
                            Frais de livraison :{" "}
                            <span className="num-font">
                                {(shipping.amount / 100)
                                    .toFixed(2)
                                    .replace(".", ",")}
                            </span>{" "}
                            €
                        </div>
                    </>
                )}
            {cartData.shippingTotal !== 0 && !addressValidate && (
                <div className="delivery">
                    Frais de livraison :{" "}
                    <span className="num-font">
                        {(cartData.shippingTotal / 100)
                            .toFixed(2)
                            .replace(".", ",")}
                    </span>{" "}
                    €
                </div>
            )}

            <div className="total-price-order">
                Prix total{" "}
                <span className="num-font">
                    {(cartData.total / 100).toFixed(2).replace(".", ",")}
                </span>{" "}
                € TTC
            </div>

            {!oneySimulationLoading && cartData.total / 100 > 100 && (
                <OneySimulation />
            )}
            <div className="logos-wrapper">
                <img
                    src={"https://static.artesane.com/images_react/cb.jpeg"}
                    alt="cb"
                />
                <img
                    src={"https://static.artesane.com/images_react/visa.png"}
                    alt="visa"
                />
                <img
                    src={
                        "https://static.artesane.com/images_react/mastercard.png"
                    }
                    alt="mastercard"
                />
            </div>
            <div className="price-infomation">Paiement 100% sécurisé</div>
            {/* {((total/100) > 100 ) && (
                <div className="paiement-oney">
                    <img src={oney} alt="Oney" />
                    <span>Paiement x 3</span>
                </div>
            )} */}
            {/* {(!pathname.includes("livraison") ||
                (cartData.shipping_address_id !== null && addressValidate  && !choosePayment))  && (
                <NavLink to={link}>
                    <div
                        className="order-button"
                        onClick={() => {
                            dispatch(redirectToOrder(true))
                            if (
                                pathname.includes("livraison") &&
                                cartData.shipping_address_id !== null
                            ) {
                                dispatch(
                                    changeInputOrder(
                                        true,
                                        "loadingValidateAddress"
                                    )
                                )
                                dispatch(fetchPaymentUrl(cartData.id))
                            }
                            // test si on valide toujours l'adresse ou non ?
                            else if (
                                pathname.includes("panier") &&
                                cartData.shipping_address_id === null &&
                                addresses.length > 0
                            ) {
                            }
                            // else if(pathname.includes("panier") && cartData.shipping_address_id !== null) {
                            //     dispatch(changeInputOrder(true, "loadingValidateAddress"));
                            //   }
                        }}>
                        {pathname.includes("livraison") ? `Payer >` : ""}
                        {!pathname.includes("livraison") ? `Commander >` : ""}
                    </div>
                </NavLink>
            )} */}
            <div className="code-gift-wrapper">
                {userConnected && filterGiftcards(giftcards).length > 0 && (
                    <div className="code-promo gift">
                        <label>J'utilise une carte cadeau</label>
                        <form
                            onSubmit={(event) => {
                                event.preventDefault()

                                if (giftCardSelected > 0) {
                                    dispatch(
                                        changeInputOrder(true, "loadingCart")
                                    )
                                    dispatch(
                                        postGiftCardCart(
                                            filterGiftcards(giftcards)[
                                                giftCardSelected - 1
                                            ].code,
                                            cartData.tokenValue
                                        )
                                    )
                                }
                            }}
                            className="form-code">
                            <div className="chose-giftcard-select">
                                {cartData.giftCards.length > 0 && (
                                    <span
                                        className="cross-delete"
                                        onClick={() => {
                                            if (giftCardSelected > 0) {
                                                dispatch(
                                                    changeInputOrder(
                                                        true,
                                                        "loadingCart"
                                                    )
                                                )
                                                dispatch(
                                                    deleteGiftCardCart(
                                                        filterGiftcards(
                                                            giftcards
                                                        )[giftCardSelected - 1]
                                                            .code,
                                                        cartData.tokenValue
                                                    )
                                                )
                                            }
                                        }}>
                                        {"✖"}
                                    </span>
                                )}

                                <select
                                    value={giftCardSelected}
                                    onChange={(event) => {
                                        dispatch(
                                            changeInputOrder(
                                                Number(event.target.value),
                                                "giftCardSelected"
                                            )
                                        )
                                    }}>
                                    <option value={0} key={0}>
                                        -----------------------------------
                                    </option>
                                    {filterGiftcards(giftcards).map(
                                        ({ id, amount, code }, index) => (
                                            <option key={id} value={index + 1}>
                                                {(amount / 100)
                                                    .toString()
                                                    .replace(".", ",") + " €"}
                                                {",  "}
                                                {code},{" "}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                            <button type="submit">Ajouter {">"}</button>
                        </form>
                        {/* <button
                        onClick={() => {

                            if(giftCardSelected > 0) {
                                dispatch(
                                    changeInputOrder(true, "loadingCart")
                                )
                                dispatch(deleteGiftCardCart(giftcards[giftCardSelected  - 1].code, cartData.tokenValue))
                            }
                        }}>
                        Supprimer{">"}
                    </button> */}
                        {/* {orderPromotion && (
                    <div className="code-result"> Coupon réduction {orderPromotion.amount/100} €</div> 
                )} */}
                    </div>
                )}
                {userConnected && (
                    <div className="code-promo">
                        J'ai un code promo
                        <form
                            onSubmit={(event) => {
                                event.preventDefault()
                                dispatch(changeInputOrder(true, "loadingCart"))
                                dispatch(
                                    postCouponCode(
                                        couponCode,
                                        cartData.tokenValue
                                    )
                                )
                            }}
                            className="form-code">
                            {/* {cartData.orderPromotionTotal !== 0 && (
                                 <span
                                    className="cross-delete"
                                    onClick={() => {
                                        // dispatch(
                                        //     changeInputOrder(true, "loadingTotalOrder")
                                        // )
                                        // dispatch(deleteCouponCode())
                                    }}
                                >
                                    {"✖"}  
                                </span>
                            )} */}
                            <input
                                required
                                value={couponCode}
                                onChange={(event) => {
                                    dispatch(
                                        changeInputOrder(
                                            "",
                                            "messageRetourCoupon"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            event.target.value,
                                            "couponCode"
                                        )
                                    )
                                }}
                                type="text"
                            />
                            <button type="submit">Mettre à jour {">"}</button>
                        </form>
                        {/* <button
                        onClick={() => {
                            dispatch(
                                changeInputOrder(true, "loadingTotalOrder")
                            )
                            dispatch(deleteCouponCode())
                        }}>
                        Supprimer{">"}
                    </button> */}
                        {/* {orderPromotion && (
                    <div className="code-result"> Coupon réduction {orderPromotion.amount/100} €</div> 
                )} */}
                        <div className="code-result">
                            {" "}
                            {messageRetourCoupon}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default TotalOrder
