import type { AppStore } from "../store/store"
import {
    countCart,
    saveCartInState,
    changeInputOrder,
} from "../actions/cartAction"
import { Address, CartAddresses } from "../types/address"

export const cartProcessor = (store: AppStore, cartData: any) => {
    changeInputOrder(cartData.tokenValue, "cartTokenUser")
    store.dispatch(saveCartInState(cartData))

    if (
        cartData.shippingAddress !== null &&
        cartData.shippingAddress !== undefined
    ) {
        store.dispatch(changeInputOrder(cartData.shipments, "shipments"))
        if (cartData.payments.length > 0) {
            store.dispatch(
                changeInputOrder(
                    cartData.payments[cartData.payments.length - 1].availableMethods.length > 1
                        ? true
                        : false,
                    "choosePayment"
                )
            )
            store.dispatch(
                changeInputOrder(
                    cartData.payments[cartData.payments.length - 1].method.code,
                    "selectPaymentCode"
                )
            )
        }
        if (cartData.promotionCoupon !== undefined) {
            if (Object.keys(cartData.promotionCoupon).length > 0) {
                store.dispatch(
                    changeInputOrder(
                        cartData.promotionCoupon.code,
                        "couponCode"
                    )
                )
            }
        }

        store.dispatch(changeInputOrder(0, "addressSelectCarnet"))
        store.dispatch(
            changeInputOrder(
                cartData.shippingAddress.firstName,
                "firstNameShipping"
            )
        )
        store.dispatch(
            changeInputOrder(
                cartData.shippingAddress.lastName,
                "lastNameShipping"
            )
        )
        store.dispatch(
            changeInputOrder(
                cartData.shippingAddress.company,
                "societyShipping"
            )
        )
        store.dispatch(
            changeInputOrder(cartData.shippingAddress.city, "cityShipping")
        )
        store.dispatch(
            changeInputOrder(cartData.shippingAddress.street, "streetShipping")
        )
        store.dispatch(
            changeInputOrder(
                cartData.shippingAddress.postcode,
                "postCodeShipping"
            )
        )
        store.dispatch(
            changeInputOrder(
                cartData.shippingAddress.countryCode,
                "countryShipping"
            )
        )
        store.dispatch(
            changeInputOrder(
                cartData.shippingAddress.phoneNumber,
                "telephoneShipping"
            )
        )
    }

    if (cartData.items.length === 0) {
        store.dispatch(countCart(0))
    }

    if (cartData.items.length === 1) {
        store.dispatch(countCart(cartData.items[0].quantity))
    } else if (cartData.items.length > 1) {
        const totalItemsCart = Object.keys(cartData.items)
            .map((variants) => cartData.items[variants].quantity)
            .reduce((previous, current) => previous + current)
        store.dispatch(countCart(totalItemsCart))
    }

    if(cartData.shipments.length > 0 ){
        if(cartData.shipments[0].pickupPointId !== undefined) {
            store.dispatch(changeInputOrder(cartData.shipments[0].pickupPointId, "mondialRelayId"))
            store.dispatch(changeInputOrder(cartData.shipments[0].pickupPostCode, "mondialRelayPostCode"))
            store.dispatch(changeInputOrder(cartData.shipments[0].pickupCountryCode, "mondialRelayCountryCode"))
            store.dispatch(changeInputOrder(cartData.shipments[0].pickupName, "mondialRelayName"))
            store.dispatch(changeInputOrder(cartData.shipments[0].pickupStreet, "mondialRelayStreet"))
            store.dispatch(changeInputOrder(cartData.shipments[0].pickupCity, "mondialRelayCity"))

        }
    }

    store.dispatch(changeInputOrder(false, "loadingCart"))
}

export const cartAddressProcessor = (store: AppStore): CartAddresses => {
    const cart = store.getState().cart
    const isSameAddress = cart.sameAddressAsShippingAddress

    let shipping: Address = {
        firstName: cart.firstNameShipping,
        lastName: cart.lastNameShipping,
        street: cart.streetShipping,
        city: cart.cityShipping,
        postcode: cart.postCodeShipping,
        countryCode: cart.countryShipping,
        phoneNumber: cart.telephoneShipping,
    }
    if (cart.societyShipping) {
        shipping.company = cart.societyShipping
    }
    var billing: Address = shipping

    if (!isSameAddress) {
        billing = {
            firstName: cart.firstNameBilling,
            lastName: cart.lastNameBilling,
            street: cart.streetBilling,
            city: cart.cityBilling,
            postcode: cart.postCodeBilling,
            countryCode: cart.countryBilling,
            phoneNumber: cart.telephoneBilling,
        }
        if (cart.societyBilling) {
            billing.company = cart.societyBilling
        }
    }

    let result: CartAddresses = {
        shippingAddress: shipping,
        billingAddress: billing,
    }
    
    if (cart.couponCode !== "") {
        result.couponCode = cart.couponCode
    }


    return result
}
